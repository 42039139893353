import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import SEO from 'react-seo-component'

import pricingPageStyle from "assets/jss/material-kit-react/views/pricingPage.jsx";

class CategoriesPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    const siteTitle = "Categle categories mapping";
    const description = "Mapping for the categories that you can find in the Categle API."
    const siteName = "Categle"   
    return (
      <div>
        <SEO 
            title={siteTitle} 
            description={description} 
            titleTemplate={siteName} 
            author="Categle" />             
        <Header
          color="transparent"
          brand="Categle.com"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div>
                    <div className={classes.name}>
                      <h1>Categle Categories</h1>
                      <table>
                        <tr>
                        <th>
                          ID
                        </th>
                        <th>
                          Description
                        </th>
                        </tr>

                        <tr><td>	100	</td><td>	Adult	</td></tr>
<tr><td>	200	</td><td>	Arts & Entertainment	</td></tr>
<tr><td>	201	</td><td>	Arts & Entertainment/Celebrities & Entertainment News	</td></tr>
<tr><td>	202	</td><td>	Arts & Entertainment/Comics & Animation	</td></tr>
<tr><td>	203	</td><td>	Arts & Entertainment/Comics & Animation/Anime & Manga	</td></tr>
<tr><td>	204	</td><td>	Arts & Entertainment/Comics & Animation/Cartoons	</td></tr>
<tr><td>	205	</td><td>	Arts & Entertainment/Comics & Animation/Comics	</td></tr>
<tr><td>	206	</td><td>	Arts & Entertainment/Entertainment Industry	</td></tr>
<tr><td>	207	</td><td>	Arts & Entertainment/Entertainment Industry/Film & TV Industry	</td></tr>
<tr><td>	208	</td><td>	Arts & Entertainment/Entertainment Industry/Recording Industry	</td></tr>
<tr><td>	209	</td><td>	Arts & Entertainment/Events & Listings	</td></tr>
<tr><td>	210	</td><td>	Arts & Entertainment/Events & Listings/Bars, Clubs & Nightlife	</td></tr>
<tr><td>	211	</td><td>	Arts & Entertainment/Events & Listings/Concerts & Music Festivals	</td></tr>
<tr><td>	212	</td><td>	Arts & Entertainment/Events & Listings/Expos & Conventions	</td></tr>
<tr><td>	213	</td><td>	Arts & Entertainment/Events & Listings/Film Festivals	</td></tr>
<tr><td>	214	</td><td>	Arts & Entertainment/Events & Listings/Movie Listings & Theater Showtimes	</td></tr>
<tr><td>	215	</td><td>	Arts & Entertainment/Fun & Trivia	</td></tr>
<tr><td>	216	</td><td>	Arts & Entertainment/Fun & Trivia/Flash-Based Entertainment	</td></tr>
<tr><td>	217	</td><td>	Arts & Entertainment/Fun & Trivia/Fun Tests & Silly Surveys	</td></tr>
<tr><td>	218	</td><td>	Arts & Entertainment/Humor	</td></tr>
<tr><td>	219	</td><td>	Arts & Entertainment/Humor/Funny Pictures & Videos	</td></tr>
<tr><td>	220	</td><td>	Arts & Entertainment/Humor/Political Humor	</td></tr>
<tr><td>	221	</td><td>	Arts & Entertainment/Movies	</td></tr>
<tr><td>	222	</td><td>	Arts & Entertainment/Music & Audio	</td></tr>
<tr><td>	223	</td><td>	Arts & Entertainment/Music & Audio/CD & Audio Shopping	</td></tr>
<tr><td>	224	</td><td>	Arts & Entertainment/Music & Audio/Classical Music	</td></tr>
<tr><td>	225	</td><td>	Arts & Entertainment/Music & Audio/Country Music	</td></tr>
<tr><td>	226	</td><td>	Arts & Entertainment/Music & Audio/Dance & Electronic Music	</td></tr>
<tr><td>	227	</td><td>	Arts & Entertainment/Music & Audio/Experimental & Industrial Music	</td></tr>
<tr><td>	228	</td><td>	Arts & Entertainment/Music & Audio/Jazz & Blues	</td></tr>
<tr><td>	229	</td><td>	Arts & Entertainment/Music & Audio/Music Education & Instruction	</td></tr>
<tr><td>	230	</td><td>	Arts & Entertainment/Music & Audio/Music Equipment & Technology	</td></tr>
<tr><td>	231	</td><td>	Arts & Entertainment/Music & Audio/Music Reference	</td></tr>
<tr><td>	232	</td><td>	Arts & Entertainment/Music & Audio/Music Streams & Downloads	</td></tr>
<tr><td>	233	</td><td>	Arts & Entertainment/Music & Audio/Music Videos	</td></tr>
<tr><td>	234	</td><td>	Arts & Entertainment/Music & Audio/Pop Music	</td></tr>
<tr><td>	235	</td><td>	Arts & Entertainment/Music & Audio/Radio	</td></tr>
<tr><td>	236	</td><td>	Arts & Entertainment/Music & Audio/Religious Music	</td></tr>
<tr><td>	237	</td><td>	Arts & Entertainment/Music & Audio/Rock Music	</td></tr>
<tr><td>	238	</td><td>	Arts & Entertainment/Music & Audio/Soundtracks	</td></tr>
<tr><td>	239	</td><td>	Arts & Entertainment/Music & Audio/Urban & Hip-Hop	</td></tr>
<tr><td>	240	</td><td>	Arts & Entertainment/Music & Audio/World Music	</td></tr>
<tr><td>	241	</td><td>	Arts & Entertainment/Offbeat	</td></tr>
<tr><td>	242	</td><td>	Arts & Entertainment/Offbeat/Occult & Paranormal	</td></tr>
<tr><td>	243	</td><td>	Arts & Entertainment/Online Media	</td></tr>
<tr><td>	244	</td><td>	Arts & Entertainment/Online Media/Online Image Galleries	</td></tr>
<tr><td>	245	</td><td>	Arts & Entertainment/Performing Arts	</td></tr>
<tr><td>	246	</td><td>	Arts & Entertainment/Performing Arts/Acting & Theater	</td></tr>
<tr><td>	247	</td><td>	Arts & Entertainment/Performing Arts/Circus	</td></tr>
<tr><td>	248	</td><td>	Arts & Entertainment/Performing Arts/Dance	</td></tr>
<tr><td>	249	</td><td>	Arts & Entertainment/Performing Arts/Magic	</td></tr>
<tr><td>	250	</td><td>	Arts & Entertainment/Performing Arts/Opera	</td></tr>
<tr><td>	251	</td><td>	Arts & Entertainment/TV & Video	</td></tr>
<tr><td>	252	</td><td>	Arts & Entertainment/TV & Video/Online Video	</td></tr>
<tr><td>	253	</td><td>	Arts & Entertainment/TV & Video/TV Commercials	</td></tr>
<tr><td>	254	</td><td>	Arts & Entertainment/TV & Video/TV Shows & Programs	</td></tr>
<tr><td>	255	</td><td>	Arts & Entertainment/Visual Art & Design	</td></tr>
<tr><td>	256	</td><td>	Arts & Entertainment/Visual Art & Design/Architecture	</td></tr>
<tr><td>	257	</td><td>	Arts & Entertainment/Visual Art & Design/Art Museums & Galleries	</td></tr>
<tr><td>	258	</td><td>	Arts & Entertainment/Visual Art & Design/Design	</td></tr>
<tr><td>	259	</td><td>	Arts & Entertainment/Visual Art & Design/Painting	</td></tr>
<tr><td>	260	</td><td>	Arts & Entertainment/Visual Art & Design/Photographic & Digital Arts	</td></tr>
<tr><td>	300	</td><td>	Autos & Vehicles	</td></tr>
<tr><td>	301	</td><td>	Autos & Vehicles/Bicycles & Accessories	</td></tr>
<tr><td>	302	</td><td>	Autos & Vehicles/Bicycles & Accessories/Bike Parts & Repair	</td></tr>
<tr><td>	303	</td><td>	Autos & Vehicles/Bicycles & Accessories/BMX Bikes	</td></tr>
<tr><td>	304	</td><td>	Autos & Vehicles/Boats & Watercraft	</td></tr>
<tr><td>	305	</td><td>	Autos & Vehicles/Campers & RVs	</td></tr>
<tr><td>	306	</td><td>	Autos & Vehicles/Classic Vehicles	</td></tr>
<tr><td>	307	</td><td>	Autos & Vehicles/Commercial Vehicles/Cargo Trucks & Trailers	</td></tr>
<tr><td>	308	</td><td>	Autos & Vehicles/Motor Vehicles (By Type)	</td></tr>
<tr><td>	309	</td><td>	Autos & Vehicles/Motor Vehicles (By Type)/Hybrid & Alternative Vehicles	</td></tr>
<tr><td>	310	</td><td>	Autos & Vehicles/Motor Vehicles (By Type)/Motorcycles	</td></tr>
<tr><td>	311	</td><td>	Autos & Vehicles/Motor Vehicles (By Type)/Off-Road Vehicles	</td></tr>
<tr><td>	312	</td><td>	Autos & Vehicles/Motor Vehicles (By Type)/Trucks & SUVs	</td></tr>
<tr><td>	313	</td><td>	Autos & Vehicles/Vehicle Codes & Driving Laws	</td></tr>
<tr><td>	314	</td><td>	Autos & Vehicles/Vehicle Codes & Driving Laws/Vehicle Licensing & Registration	</td></tr>
<tr><td>	315	</td><td>	Autos & Vehicles/Vehicle Parts & Services	</td></tr>
<tr><td>	316	</td><td>	Autos & Vehicles/Vehicle Parts & Services/Gas Prices & Vehicle Fueling	</td></tr>
<tr><td>	317	</td><td>	Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories	</td></tr>
<tr><td>	318	</td><td>	Autos & Vehicles/Vehicle Parts & Services/Vehicle Repair & Maintenance	</td></tr>
<tr><td>	319	</td><td>	Autos & Vehicles/Vehicle Shopping	</td></tr>
<tr><td>	320	</td><td>	Autos & Vehicles/Vehicle Shopping/Used Vehicles	</td></tr>
<tr><td>	321	</td><td>	Autos & Vehicles/Vehicle Shows	</td></tr>
<tr><td>	400	</td><td>	Beauty & Fitness	</td></tr>
<tr><td>	401	</td><td>	Beauty & Fitness/Beauty Pageants	</td></tr>
<tr><td>	402	</td><td>	Beauty & Fitness/Body Art	</td></tr>
<tr><td>	403	</td><td>	Beauty & Fitness/Cosmetic Procedures	</td></tr>
<tr><td>	404	</td><td>	Beauty & Fitness/Cosmetic Procedures/Cosmetic Surgery	</td></tr>
<tr><td>	405	</td><td>	Beauty & Fitness/Cosmetology & Beauty Professionals	</td></tr>
<tr><td>	406	</td><td>	Beauty & Fitness/Face & Body Care	</td></tr>
<tr><td>	407	</td><td>	Beauty & Fitness/Face & Body Care/Hygiene & Toiletries	</td></tr>
<tr><td>	408	</td><td>	Beauty & Fitness/Face & Body Care/Make-Up & Cosmetics	</td></tr>
<tr><td>	409	</td><td>	Beauty & Fitness/Face & Body Care/Perfumes & Fragrances	</td></tr>
<tr><td>	410	</td><td>	Beauty & Fitness/Face & Body Care/Skin & Nail Care	</td></tr>
<tr><td>	411	</td><td>	Beauty & Fitness/Face & Body Care/Unwanted Body & Facial Hair Removal	</td></tr>
<tr><td>	412	</td><td>	Beauty & Fitness/Fashion & Style	</td></tr>
<tr><td>	413	</td><td>	Beauty & Fitness/Fashion & Style/Fashion Designers & Collections	</td></tr>
<tr><td>	414	</td><td>	Beauty & Fitness/Fitness	</td></tr>
<tr><td>	415	</td><td>	Beauty & Fitness/Hair Care	</td></tr>
<tr><td>	416	</td><td>	Beauty & Fitness/Hair Care/Hair Loss	</td></tr>
<tr><td>	417	</td><td>	Beauty & Fitness/Spas & Beauty Services	</td></tr>
<tr><td>	418	</td><td>	Beauty & Fitness/Spas & Beauty Services/Massage Therapy	</td></tr>
<tr><td>	419	</td><td>	Beauty & Fitness/Weight Loss	</td></tr>
<tr><td>	500	</td><td>	Books & Literature	</td></tr>
<tr><td>	501	</td><td>	Books & Literature/Children's Literature	</td></tr>
<tr><td>	502	</td><td>	Books & Literature/E-Books	</td></tr>
<tr><td>	503	</td><td>	Books & Literature/Fan Fiction	</td></tr>
<tr><td>	504	</td><td>	Books & Literature/Literary Classics	</td></tr>
<tr><td>	505	</td><td>	Books & Literature/Poetry	</td></tr>
<tr><td>	506	</td><td>	Books & Literature/Writers Resources	</td></tr>
<tr><td>	600	</td><td>	Business & Industrial	</td></tr>
<tr><td>	601	</td><td>	Business & Industrial/Advertising & Marketing/Public Relations	</td></tr>
<tr><td>	602	</td><td>	Business & Industrial/Aerospace & Defense/Space Technology	</td></tr>
<tr><td>	603	</td><td>	Business & Industrial/Agriculture & Forestry	</td></tr>
<tr><td>	604	</td><td>	Business & Industrial/Agriculture & Forestry/Agricultural Equipment	</td></tr>
<tr><td>	605	</td><td>	Business & Industrial/Agriculture & Forestry/Forestry	</td></tr>
<tr><td>	606	</td><td>	Business & Industrial/Agriculture & Forestry/Livestock	</td></tr>
<tr><td>	607	</td><td>	Business & Industrial/Automotive Industry	</td></tr>
<tr><td>	608	</td><td>	Business & Industrial/Business Education	</td></tr>
<tr><td>	609	</td><td>	Business & Industrial/Business Finance	</td></tr>
<tr><td>	610	</td><td>	Business & Industrial/Business Finance/Venture Capital	</td></tr>
<tr><td>	611	</td><td>	Business & Industrial/Business Operations	</td></tr>
<tr><td>	612	</td><td>	Business & Industrial/Business Operations/Business Plans & Presentations	</td></tr>
<tr><td>	613	</td><td>	Business & Industrial/Business Operations/Management	</td></tr>
<tr><td>	614	</td><td>	Business & Industrial/Business Services	</td></tr>
<tr><td>	615	</td><td>	Business & Industrial/Business Services/Consulting	</td></tr>
<tr><td>	616	</td><td>	Business & Industrial/Business Services/Corporate Events	</td></tr>
<tr><td>	617	</td><td>	Business & Industrial/Business Services/E-Commerce Services	</td></tr>
<tr><td>	618	</td><td>	Business & Industrial/Business Services/Fire & Security Services	</td></tr>
<tr><td>	619	</td><td>	Business & Industrial/Business Services/Office Services	</td></tr>
<tr><td>	620	</td><td>	Business & Industrial/Business Services/Office Supplies	</td></tr>
<tr><td>	621	</td><td>	Business & Industrial/Business Services/Writing & Editing Services	</td></tr>
<tr><td>	622	</td><td>	Business & Industrial/Chemicals Industry	</td></tr>
<tr><td>	623	</td><td>	Business & Industrial/Chemicals Industry/Cleaning Agents	</td></tr>
<tr><td>	624	</td><td>	Business & Industrial/Chemicals Industry/Plastics & Polymers	</td></tr>
<tr><td>	625	</td><td>	Business & Industrial/Construction & Maintenance	</td></tr>
<tr><td>	626	</td><td>	Business & Industrial/Construction & Maintenance/Building Materials & Supplies	</td></tr>
<tr><td>	627	</td><td>	Business & Industrial/Energy & Utilities	</td></tr>
<tr><td>	628	</td><td>	Business & Industrial/Energy & Utilities/Electricity	</td></tr>
<tr><td>	629	</td><td>	Business & Industrial/Energy & Utilities/Oil & Gas	</td></tr>
<tr><td>	630	</td><td>	Business & Industrial/Energy & Utilities/Renewable & Alternative Energy	</td></tr>
<tr><td>	631	</td><td>	Business & Industrial/Hospitality Industry	</td></tr>
<tr><td>	632	</td><td>	Business & Industrial/Hospitality Industry/Event Planning	</td></tr>
<tr><td>	633	</td><td>	Business & Industrial/Hospitality Industry/Food Service	</td></tr>
<tr><td>	634	</td><td>	Business & Industrial/Industrial Materials & Equipment	</td></tr>
<tr><td>	635	</td><td>	Business & Industrial/Industrial Materials & Equipment/Heavy Machinery	</td></tr>
<tr><td>	636	</td><td>	Business & Industrial/Manufacturing	</td></tr>
<tr><td>	637	</td><td>	Business & Industrial/Metals & Mining	</td></tr>
<tr><td>	638	</td><td>	Business & Industrial/Metals & Mining/Precious Metals	</td></tr>
<tr><td>	639	</td><td>	Business & Industrial/Pharmaceuticals & Biotech	</td></tr>
<tr><td>	640	</td><td>	Business & Industrial/Printing & Publishing	</td></tr>
<tr><td>	641	</td><td>	Business & Industrial/Retail Trade	</td></tr>
<tr><td>	642	</td><td>	Business & Industrial/Retail Trade/Retail Equipment & Technology	</td></tr>
<tr><td>	643	</td><td>	Business & Industrial/Small Business/MLM & Business Opportunities	</td></tr>
<tr><td>	644	</td><td>	Business & Industrial/Textiles & Nonwovens	</td></tr>
<tr><td>	645	</td><td>	Business & Industrial/Transportation & Logistics	</td></tr>
<tr><td>	646	</td><td>	Business & Industrial/Transportation & Logistics/Freight & Trucking	</td></tr>
<tr><td>	647	</td><td>	Business & Industrial/Transportation & Logistics/Mail & Package Delivery	</td></tr>
<tr><td>	648	</td><td>	Business & Industrial/Transportation & Logistics/Maritime Transport	</td></tr>
<tr><td>	649	</td><td>	Business & Industrial/Transportation & Logistics/Moving & Relocation	</td></tr>
<tr><td>	650	</td><td>	Business & Industrial/Transportation & Logistics/Packaging	</td></tr>
<tr><td>	651	</td><td>	Business & Industrial/Transportation & Logistics/Parking	</td></tr>
<tr><td>	652	</td><td>	Business & Industrial/Transportation & Logistics/Rail Transport	</td></tr>
<tr><td>	653	</td><td>	Business & Industrial/Transportation & Logistics/Urban Transport	</td></tr>
<tr><td>	700	</td><td>	Computers & Electronics	</td></tr>
<tr><td>	701	</td><td>	Computers & Electronics/CAD & CAM	</td></tr>
<tr><td>	702	</td><td>	Computers & Electronics/Computer Hardware	</td></tr>
<tr><td>	703	</td><td>	Computers & Electronics/Computer Hardware/Computer Components	</td></tr>
<tr><td>	704	</td><td>	Computers & Electronics/Computer Hardware/Computer Drives & Storage	</td></tr>
<tr><td>	705	</td><td>	Computers & Electronics/Computer Hardware/Computer Peripherals	</td></tr>
<tr><td>	706	</td><td>	Computers & Electronics/Computer Hardware/Desktop Computers	</td></tr>
<tr><td>	707	</td><td>	Computers & Electronics/Computer Hardware/Laptops & Notebooks	</td></tr>
<tr><td>	708	</td><td>	Computers & Electronics/Computer Security	</td></tr>
<tr><td>	709	</td><td>	Computers & Electronics/Computer Security/Hacking & Cracking	</td></tr>
<tr><td>	710	</td><td>	Computers & Electronics/Consumer Electronics	</td></tr>
<tr><td>	711	</td><td>	Computers & Electronics/Consumer Electronics/Audio Equipment	</td></tr>
<tr><td>	712	</td><td>	Computers & Electronics/Consumer Electronics/Camera & Photo Equipment	</td></tr>
<tr><td>	713	</td><td>	Computers & Electronics/Consumer Electronics/Car Electronics	</td></tr>
<tr><td>	714	</td><td>	Computers & Electronics/Consumer Electronics/Drones & RC Aircraft	</td></tr>
<tr><td>	715	</td><td>	Computers & Electronics/Consumer Electronics/Game Systems & Consoles	</td></tr>
<tr><td>	716	</td><td>	Computers & Electronics/Consumer Electronics/GPS & Navigation	</td></tr>
<tr><td>	717	</td><td>	Computers & Electronics/Consumer Electronics/TV & Video Equipment	</td></tr>
<tr><td>	718	</td><td>	Computers & Electronics/Electronics & Electrical	</td></tr>
<tr><td>	719	</td><td>	Computers & Electronics/Electronics & Electrical/Electronic Components	</td></tr>
<tr><td>	720	</td><td>	Computers & Electronics/Electronics & Electrical/Power Supplies	</td></tr>
<tr><td>	721	</td><td>	Computers & Electronics/Enterprise Technology	</td></tr>
<tr><td>	722	</td><td>	Computers & Electronics/Enterprise Technology/Data Management	</td></tr>
<tr><td>	723	</td><td>	Computers & Electronics/Networking	</td></tr>
<tr><td>	724	</td><td>	Computers & Electronics/Networking/Data Formats & Protocols	</td></tr>
<tr><td>	725	</td><td>	Computers & Electronics/Networking/Network Monitoring & Management	</td></tr>
<tr><td>	726	</td><td>	Computers & Electronics/Networking/VPN & Remote Access	</td></tr>
<tr><td>	727	</td><td>	Computers & Electronics/Programming	</td></tr>
<tr><td>	728	</td><td>	Computers & Electronics/Programming/Java (Programming Language)	</td></tr>
<tr><td>	729	</td><td>	Computers & Electronics/Software	</td></tr>
<tr><td>	730	</td><td>	Computers & Electronics/Software/Business & Productivity Software	</td></tr>
<tr><td>	731	</td><td>	Computers & Electronics/Software/Device Drivers	</td></tr>
<tr><td>	732	</td><td>	Computers & Electronics/Software/Internet Software	</td></tr>
<tr><td>	733	</td><td>	Computers & Electronics/Software/Multimedia Software	</td></tr>
<tr><td>	734	</td><td>	Computers & Electronics/Software/Operating Systems	</td></tr>
<tr><td>	735	</td><td>	Computers & Electronics/Software/Software Utilities	</td></tr>
<tr><td>	800	</td><td>	Finance	</td></tr>
<tr><td>	801	</td><td>	Finance/Accounting & Auditing	</td></tr>
<tr><td>	802	</td><td>	Finance/Accounting & Auditing/Billing & Invoicing	</td></tr>
<tr><td>	803	</td><td>	Finance/Accounting & Auditing/Tax Preparation & Planning	</td></tr>
<tr><td>	804	</td><td>	Finance/Banking	</td></tr>
<tr><td>	805	</td><td>	Finance/Credit & Lending	</td></tr>
<tr><td>	806	</td><td>	Finance/Credit & Lending/Credit Cards	</td></tr>
<tr><td>	807	</td><td>	Finance/Credit & Lending/Credit Reporting & Monitoring	</td></tr>
<tr><td>	808	</td><td>	Finance/Credit & Lending/Loans	</td></tr>
<tr><td>	809	</td><td>	Finance/Financial Planning & Management	</td></tr>
<tr><td>	810	</td><td>	Finance/Financial Planning & Management/Retirement & Pension	</td></tr>
<tr><td>	811	</td><td>	Finance/Grants, Scholarships & Financial Aid	</td></tr>
<tr><td>	812	</td><td>	Finance/Grants, Scholarships & Financial Aid/Study Grants & Scholarships	</td></tr>
<tr><td>	813	</td><td>	Finance/Insurance	</td></tr>
<tr><td>	814	</td><td>	Finance/Insurance/Health Insurance	</td></tr>
<tr><td>	815	</td><td>	Finance/Investing	</td></tr>
<tr><td>	816	</td><td>	Finance/Investing/Commodities & Futures Trading	</td></tr>
<tr><td>	817	</td><td>	Finance/Investing/Currencies & Foreign Exchange	</td></tr>
<tr><td>	818	</td><td>	Finance/Investing/Stocks & Bonds	</td></tr>
<tr><td>	900	</td><td>	Food & Drink	</td></tr>
<tr><td>	901	</td><td>	Food & Drink/Beverages	</td></tr>
<tr><td>	902	</td><td>	Food & Drink/Beverages/Alcoholic Beverages	</td></tr>
<tr><td>	903	</td><td>	Food & Drink/Beverages/Coffee & Tea	</td></tr>
<tr><td>	904	</td><td>	Food & Drink/Beverages/Juice	</td></tr>
<tr><td>	905	</td><td>	Food & Drink/Beverages/Soft Drinks	</td></tr>
<tr><td>	906	</td><td>	Food & Drink/Cooking & Recipes	</td></tr>
<tr><td>	907	</td><td>	Food & Drink/Cooking & Recipes/BBQ & Grilling	</td></tr>
<tr><td>	908	</td><td>	Food & Drink/Cooking & Recipes/Desserts	</td></tr>
<tr><td>	909	</td><td>	Food & Drink/Cooking & Recipes/Soups & Stews	</td></tr>
<tr><td>	910	</td><td>	Food & Drink/Food	</td></tr>
<tr><td>	911	</td><td>	Food & Drink/Food & Grocery Retailers	</td></tr>
<tr><td>	912	</td><td>	Food & Drink/Food/Baked Goods	</td></tr>
<tr><td>	913	</td><td>	Food & Drink/Food/Breakfast Foods	</td></tr>
<tr><td>	914	</td><td>	Food & Drink/Food/Candy & Sweets	</td></tr>
<tr><td>	915	</td><td>	Food & Drink/Food/Grains & Pasta	</td></tr>
<tr><td>	916	</td><td>	Food & Drink/Food/Meat & Seafood	</td></tr>
<tr><td>	917	</td><td>	Food & Drink/Food/Snack Foods	</td></tr>
<tr><td>	918	</td><td>	Food & Drink/Restaurants	</td></tr>
<tr><td>	919	</td><td>	Food & Drink/Restaurants/Fast Food	</td></tr>
<tr><td>	920	</td><td>	Food & Drink/Restaurants/Pizzerias	</td></tr>
<tr><td>	921	</td><td>	Food & Drink/Restaurants/Restaurant Reviews & Reservations	</td></tr>
<tr><td>	1000	</td><td>	Games	</td></tr>
<tr><td>	1001	</td><td>	Games/Arcade & Coin-Op Games	</td></tr>
<tr><td>	1002	</td><td>	Games/Board Games	</td></tr>
<tr><td>	1003	</td><td>	Games/Board Games/Chess & Abstract Strategy Games	</td></tr>
<tr><td>	1004	</td><td>	Games/Board Games/Miniatures & Wargaming	</td></tr>
<tr><td>	1005	</td><td>	Games/Card Games	</td></tr>
<tr><td>	1006	</td><td>	Games/Card Games/Collectible Card Games	</td></tr>
<tr><td>	1007	</td><td>	Games/Card Games/Poker & Casino Games	</td></tr>
<tr><td>	1008	</td><td>	Games/Computer & Video Games	</td></tr>
<tr><td>	1009	</td><td>	Games/Computer & Video Games/Casual Games	</td></tr>
<tr><td>	1010	</td><td>	Games/Computer & Video Games/Driving & Racing Games	</td></tr>
<tr><td>	1011	</td><td>	Games/Computer & Video Games/Fighting Games	</td></tr>
<tr><td>	1012	</td><td>	Games/Computer & Video Games/Music & Dance Games	</td></tr>
<tr><td>	1013	</td><td>	Games/Computer & Video Games/Sandbox Games	</td></tr>
<tr><td>	1014	</td><td>	Games/Computer & Video Games/Shooter Games	</td></tr>
<tr><td>	1015	</td><td>	Games/Computer & Video Games/Simulation Games	</td></tr>
<tr><td>	1016	</td><td>	Games/Computer & Video Games/Sports Games	</td></tr>
<tr><td>	1017	</td><td>	Games/Computer & Video Games/Strategy Games	</td></tr>
<tr><td>	1018	</td><td>	Games/Computer & Video Games/Video Game Emulation	</td></tr>
<tr><td>	1019	</td><td>	Games/Family-Oriented Games & Activities	</td></tr>
<tr><td>	1020	</td><td>	Games/Family-Oriented Games & Activities/Drawing & Coloring	</td></tr>
<tr><td>	1021	</td><td>	Games/Family-Oriented Games & Activities/Dress-Up & Fashion Games	</td></tr>
<tr><td>	1022	</td><td>	Games/Gambling	</td></tr>
<tr><td>	1023	</td><td>	Games/Gambling/Lottery	</td></tr>
<tr><td>	1024	</td><td>	Games/Online Games/Massively Multiplayer Games	</td></tr>
<tr><td>	1025	</td><td>	Games/Puzzles & Brainteasers	</td></tr>
<tr><td>	1026	</td><td>	Games/Roleplaying Games	</td></tr>
<tr><td>	1027	</td><td>	Games/Table Games	</td></tr>
<tr><td>	1028	</td><td>	Games/Table Games/Billiards	</td></tr>
<tr><td>	1029	</td><td>	Games/Word Games	</td></tr>
<tr><td>	1100	</td><td>	Health	</td></tr>
<tr><td>	1101	</td><td>	Health/Aging & Geriatrics	</td></tr>
<tr><td>	1102	</td><td>	Health/Health Conditions	</td></tr>
<tr><td>	1103	</td><td>	Health/Health Conditions/AIDS & HIV	</td></tr>
<tr><td>	1104	</td><td>	Health/Health Conditions/Allergies	</td></tr>
<tr><td>	1105	</td><td>	Health/Health Conditions/Arthritis	</td></tr>
<tr><td>	1106	</td><td>	Health/Health Conditions/Cancer	</td></tr>
<tr><td>	1107	</td><td>	Health/Health Conditions/Diabetes	</td></tr>
<tr><td>	1108	</td><td>	Health/Health Conditions/Ear Nose & Throat	</td></tr>
<tr><td>	1109	</td><td>	Health/Health Conditions/Eating Disorders	</td></tr>
<tr><td>	1110	</td><td>	Health/Health Conditions/Endocrine Conditions	</td></tr>
<tr><td>	1111	</td><td>	Health/Health Conditions/Genetic Disorders	</td></tr>
<tr><td>	1112	</td><td>	Health/Health Conditions/Heart & Hypertension	</td></tr>
<tr><td>	1113	</td><td>	Health/Health Conditions/Infectious Diseases	</td></tr>
<tr><td>	1114	</td><td>	Health/Health Conditions/Neurological Conditions	</td></tr>
<tr><td>	1115	</td><td>	Health/Health Conditions/Obesity	</td></tr>
<tr><td>	1116	</td><td>	Health/Health Conditions/Pain Management	</td></tr>
<tr><td>	1117	</td><td>	Health/Health Conditions/Respiratory Conditions	</td></tr>
<tr><td>	1118	</td><td>	Health/Health Conditions/Skin Conditions	</td></tr>
<tr><td>	1119	</td><td>	Health/Health Conditions/Sleep Disorders	</td></tr>
<tr><td>	1120	</td><td>	Health/Health Education & Medical Training	</td></tr>
<tr><td>	1121	</td><td>	Health/Health Foundations & Medical Research	</td></tr>
<tr><td>	1122	</td><td>	Health/Medical Devices & Equipment	</td></tr>
<tr><td>	1123	</td><td>	Health/Medical Facilities & Services	</td></tr>
<tr><td>	1124	</td><td>	Health/Medical Facilities & Services/Doctors' Offices	</td></tr>
<tr><td>	1125	</td><td>	Health/Medical Facilities & Services/Hospitals & Treatment Centers	</td></tr>
<tr><td>	1126	</td><td>	Health/Medical Facilities & Services/Medical Procedures	</td></tr>
<tr><td>	1127	</td><td>	Health/Medical Facilities & Services/Physical Therapy	</td></tr>
<tr><td>	1128	</td><td>	Health/Men's Health	</td></tr>
<tr><td>	1129	</td><td>	Health/Mental Health	</td></tr>
<tr><td>	1130	</td><td>	Health/Mental Health/Anxiety & Stress	</td></tr>
<tr><td>	1131	</td><td>	Health/Mental Health/Depression	</td></tr>
<tr><td>	1132	</td><td>	Health/Nursing	</td></tr>
<tr><td>	1133	</td><td>	Health/Nursing/Assisted Living & Long Term Care	</td></tr>
<tr><td>	1134	</td><td>	Health/Nutrition	</td></tr>
<tr><td>	1135	</td><td>	Health/Nutrition/Special & Restricted Diets	</td></tr>
<tr><td>	1136	</td><td>	Health/Nutrition/Vitamins & Supplements	</td></tr>
<tr><td>	1137	</td><td>	Health/Oral & Dental Care	</td></tr>
<tr><td>	1138	</td><td>	Health/Pharmacy	</td></tr>
<tr><td>	1139	</td><td>	Health/Pharmacy/Drugs & Medications	</td></tr>
<tr><td>	1140	</td><td>	Health/Public Health	</td></tr>
<tr><td>	1141	</td><td>	Health/Public Health/Occupational Health & Safety	</td></tr>
<tr><td>	1142	</td><td>	Health/Reproductive Health	</td></tr>
<tr><td>	1143	</td><td>	Health/Substance Abuse	</td></tr>
<tr><td>	1144	</td><td>	Health/Substance Abuse/Drug & Alcohol Testing	</td></tr>
<tr><td>	1145	</td><td>	Health/Substance Abuse/Drug & Alcohol Treatment	</td></tr>
<tr><td>	1146	</td><td>	Health/Substance Abuse/Smoking & Smoking Cessation	</td></tr>
<tr><td>	1147	</td><td>	Health/Substance Abuse/Steroids & Performance-Enhancing Drugs	</td></tr>
<tr><td>	1148	</td><td>	Health/Vision Care	</td></tr>
<tr><td>	1149	</td><td>	Health/Vision Care/Eyeglasses & Contacts	</td></tr>
<tr><td>	1150	</td><td>	Health/Women's Health	</td></tr>
<tr><td>	1200	</td><td>	Hobbies & Leisure	</td></tr>
<tr><td>	1201	</td><td>	Hobbies & Leisure/Clubs & Organizations	</td></tr>
<tr><td>	1202	</td><td>	Hobbies & Leisure/Clubs & Organizations/Youth Organizations & Resources	</td></tr>
<tr><td>	1203	</td><td>	Hobbies & Leisure/Crafts	</td></tr>
<tr><td>	1204	</td><td>	Hobbies & Leisure/Crafts/Fiber & Textile Arts	</td></tr>
<tr><td>	1205	</td><td>	Hobbies & Leisure/Merit Prizes & Contests	</td></tr>
<tr><td>	1206	</td><td>	Hobbies & Leisure/Outdoors	</td></tr>
<tr><td>	1207	</td><td>	Hobbies & Leisure/Outdoors/Fishing	</td></tr>
<tr><td>	1208	</td><td>	Hobbies & Leisure/Outdoors/Hiking & Camping	</td></tr>
<tr><td>	1209	</td><td>	Hobbies & Leisure/Paintball	</td></tr>
<tr><td>	1210	</td><td>	Hobbies & Leisure/Radio Control & Modeling	</td></tr>
<tr><td>	1211	</td><td>	Hobbies & Leisure/Radio Control & Modeling/Model Trains & Railroads	</td></tr>
<tr><td>	1212	</td><td>	Hobbies & Leisure/Special Occasions	</td></tr>
<tr><td>	1213	</td><td>	Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events	</td></tr>
<tr><td>	1214	</td><td>	Hobbies & Leisure/Special Occasions/Weddings	</td></tr>
<tr><td>	1215	</td><td>	Hobbies & Leisure/Water Activities	</td></tr>
<tr><td>	1216	</td><td>	Hobbies & Leisure/Water Activities/Boating	</td></tr>
<tr><td>	1217	</td><td>	Hobbies & Leisure/Water Activities/Surf & Swim	</td></tr>
<tr><td>	1300	</td><td>	Home & Garden	</td></tr>
<tr><td>	1301	</td><td>	Home & Garden/Bed & Bath	</td></tr>
<tr><td>	1302	</td><td>	Home & Garden/Bed & Bath/Bathroom	</td></tr>
<tr><td>	1303	</td><td>	Home & Garden/Domestic Services	</td></tr>
<tr><td>	1304	</td><td>	Home & Garden/Domestic Services/Cleaning Services	</td></tr>
<tr><td>	1305	</td><td>	Home & Garden/Gardening & Landscaping	</td></tr>
<tr><td>	1306	</td><td>	Home & Garden/Home & Interior Decor	</td></tr>
<tr><td>	1307	</td><td>	Home & Garden/Home Appliances	</td></tr>
<tr><td>	1308	</td><td>	Home & Garden/Home Furnishings	</td></tr>
<tr><td>	1309	</td><td>	Home & Garden/Home Furnishings/Curtains & Window Treatments	</td></tr>
<tr><td>	1310	</td><td>	Home & Garden/Home Furnishings/Kitchen & Dining Furniture	</td></tr>
<tr><td>	1311	</td><td>	Home & Garden/Home Furnishings/Lamps & Lighting	</td></tr>
<tr><td>	1312	</td><td>	Home & Garden/Home Furnishings/Living Room Furniture	</td></tr>
<tr><td>	1313	</td><td>	Home & Garden/Home Furnishings/Rugs & Carpets	</td></tr>
<tr><td>	1314	</td><td>	Home & Garden/Home Improvement	</td></tr>
<tr><td>	1315	</td><td>	Home & Garden/Home Improvement/Construction & Power Tools	</td></tr>
<tr><td>	1316	</td><td>	Home & Garden/Home Improvement/Doors & Windows	</td></tr>
<tr><td>	1317	</td><td>	Home & Garden/Home Improvement/Flooring	</td></tr>
<tr><td>	1318	</td><td>	Home & Garden/Home Improvement/House Painting & Finishing	</td></tr>
<tr><td>	1319	</td><td>	Home & Garden/Home Improvement/Plumbing	</td></tr>
<tr><td>	1320	</td><td>	Home & Garden/Home Safety & Security	</td></tr>
<tr><td>	1321	</td><td>	Home & Garden/Home Storage & Shelving	</td></tr>
<tr><td>	1322	</td><td>	Home & Garden/Home Swimming Pools, Saunas & Spas	</td></tr>
<tr><td>	1323	</td><td>	Home & Garden/HVAC & Climate Control	</td></tr>
<tr><td>	1324	</td><td>	Home & Garden/HVAC & Climate Control/Fireplaces & Stoves	</td></tr>
<tr><td>	1325	</td><td>	Home & Garden/Kitchen & Dining	</td></tr>
<tr><td>	1326	</td><td>	Home & Garden/Kitchen & Dining/Cookware & Diningware	</td></tr>
<tr><td>	1327	</td><td>	Home & Garden/Kitchen & Dining/Major Kitchen Appliances	</td></tr>
<tr><td>	1328	</td><td>	Home & Garden/Kitchen & Dining/Small Kitchen Appliances	</td></tr>
<tr><td>	1329	</td><td>	Home & Garden/Laundry	</td></tr>
<tr><td>	1330	</td><td>	Home & Garden/Laundry/Washers & Dryers	</td></tr>
<tr><td>	1331	</td><td>	Home & Garden/Nursery & Playroom	</td></tr>
<tr><td>	1332	</td><td>	Home & Garden/Pest Control	</td></tr>
<tr><td>	1333	</td><td>	Home & Garden/Yard & Patio	</td></tr>
<tr><td>	1334	</td><td>	Home & Garden/Yard & Patio/Lawn Mowers	</td></tr>
<tr><td>	1400	</td><td>	Internet & Telecom	</td></tr>
<tr><td>	1401	</td><td>	Internet & Telecom/Communications Equipment	</td></tr>
<tr><td>	1402	</td><td>	Internet & Telecom/Communications Equipment/Radio Equipment	</td></tr>
<tr><td>	1403	</td><td>	Internet & Telecom/Email & Messaging	</td></tr>
<tr><td>	1404	</td><td>	Internet & Telecom/Email & Messaging/Text & Instant Messaging	</td></tr>
<tr><td>	1405	</td><td>	Internet & Telecom/Email & Messaging/Voice & Video Chat	</td></tr>
<tr><td>	1406	</td><td>	Internet & Telecom/Mobile & Wireless	</td></tr>
<tr><td>	1407	</td><td>	Internet & Telecom/Mobile & Wireless/Mobile & Wireless Accessories	</td></tr>
<tr><td>	1408	</td><td>	Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons	</td></tr>
<tr><td>	1409	</td><td>	Internet & Telecom/Mobile & Wireless/Mobile Phones	</td></tr>
<tr><td>	1410	</td><td>	Internet & Telecom/Service Providers	</td></tr>
<tr><td>	1411	</td><td>	Internet & Telecom/Service Providers/Cable & Satellite Providers	</td></tr>
<tr><td>	1412	</td><td>	Internet & Telecom/Web Services	</td></tr>
<tr><td>	1413	</td><td>	Internet & Telecom/Web Services/Affiliate Programs	</td></tr>
<tr><td>	1414	</td><td>	Internet & Telecom/Web Services/Web Design & Development	</td></tr>
<tr><td>	1500	</td><td>	Jobs & Education	</td></tr>
<tr><td>	1501	</td><td>	Jobs & Education/Education	</td></tr>
<tr><td>	1502	</td><td>	Jobs & Education/Education/Colleges & Universities	</td></tr>
<tr><td>	1503	</td><td>	Jobs & Education/Education/Distance Learning	</td></tr>
<tr><td>	1504	</td><td>	Jobs & Education/Education/Homeschooling	</td></tr>
<tr><td>	1505	</td><td>	Jobs & Education/Education/Primary & Secondary Schooling (K-12)	</td></tr>
<tr><td>	1506	</td><td>	Jobs & Education/Education/Standardized & Admissions Tests	</td></tr>
<tr><td>	1507	</td><td>	Jobs & Education/Education/Teaching & Classroom Resources	</td></tr>
<tr><td>	1508	</td><td>	Jobs & Education/Education/Training & Certification	</td></tr>
<tr><td>	1509	</td><td>	Jobs & Education/Education/Vocational & Continuing Education	</td></tr>
<tr><td>	1510	</td><td>	Jobs & Education/Jobs	</td></tr>
<tr><td>	1511	</td><td>	Jobs & Education/Jobs/Career Resources & Planning	</td></tr>
<tr><td>	1512	</td><td>	Jobs & Education/Jobs/Job Listings	</td></tr>
<tr><td>	1513	</td><td>	Jobs & Education/Jobs/Resumes & Portfolios	</td></tr>
<tr><td>	1600	</td><td>	Law & Government	</td></tr>
<tr><td>	1601	</td><td>	Law & Government/Government	</td></tr>
<tr><td>	1602	</td><td>	Law & Government/Government/Courts & Judiciary	</td></tr>
<tr><td>	1603	</td><td>	Law & Government/Government/Visa & Immigration	</td></tr>
<tr><td>	1604	</td><td>	Law & Government/Legal	</td></tr>
<tr><td>	1605	</td><td>	Law & Government/Legal/Bankruptcy	</td></tr>
<tr><td>	1606	</td><td>	Law & Government/Legal/Legal Education	</td></tr>
<tr><td>	1607	</td><td>	Law & Government/Legal/Legal Services	</td></tr>
<tr><td>	1608	</td><td>	Law & Government/Military	</td></tr>
<tr><td>	1609	</td><td>	Law & Government/Public Safety	</td></tr>
<tr><td>	1610	</td><td>	Law & Government/Public Safety/Crime & Justice	</td></tr>
<tr><td>	1611	</td><td>	Law & Government/Public Safety/Emergency Services	</td></tr>
<tr><td>	1612	</td><td>	Law & Government/Public Safety/Law Enforcement	</td></tr>
<tr><td>	1613	</td><td>	Law & Government/Public Safety/Security Products & Services	</td></tr>
<tr><td>	1614	</td><td>	Law & Government/Social Services	</td></tr>
<tr><td>	1700	</td><td>	News	</td></tr>
<tr><td>	1701	</td><td>	News/Business News	</td></tr>
<tr><td>	1702	</td><td>	News/Business News/Company News	</td></tr>
<tr><td>	1703	</td><td>	News/Business News/Financial Markets News	</td></tr>
<tr><td>	1704	</td><td>	News/Gossip & Tabloid News/Scandals & Investigations	</td></tr>
<tr><td>	1705	</td><td>	News/Health News	</td></tr>
<tr><td>	1706	</td><td>	News/Politics	</td></tr>
<tr><td>	1707	</td><td>	News/Sports News	</td></tr>
<tr><td>	1708	</td><td>	News/Weather	</td></tr>
<tr><td>	1800	</td><td>	Online Communities	</td></tr>
<tr><td>	1801	</td><td>	Online Communities/Blogging Resources & Services	</td></tr>
<tr><td>	1802	</td><td>	Online Communities/Dating & Personals	</td></tr>
<tr><td>	1803	</td><td>	Online Communities/Dating & Personals/Matrimonial Services	</td></tr>
<tr><td>	1804	</td><td>	Online Communities/Dating & Personals/Personals	</td></tr>
<tr><td>	1805	</td><td>	Online Communities/Dating & Personals/Photo Rating Sites	</td></tr>
<tr><td>	1806	</td><td>	Online Communities/File Sharing & Hosting	</td></tr>
<tr><td>	1807	</td><td>	Online Communities/Online Goodies	</td></tr>
<tr><td>	1808	</td><td>	Online Communities/Online Goodies/Clip Art & Animated GIFs	</td></tr>
<tr><td>	1809	</td><td>	Online Communities/Online Goodies/Skins, Themes & Wallpapers	</td></tr>
<tr><td>	1810	</td><td>	Online Communities/Online Goodies/Social Network Apps & Add-Ons	</td></tr>
<tr><td>	1811	</td><td>	Online Communities/Photo & Video Sharing	</td></tr>
<tr><td>	1812	</td><td>	Online Communities/Photo & Video Sharing/Photo & Image Sharing	</td></tr>
<tr><td>	1813	</td><td>	Online Communities/Social Networks	</td></tr>
<tr><td>	1814	</td><td>	Online Communities/Virtual Worlds	</td></tr>
<tr><td>	1900	</td><td>	People & Society	</td></tr>
<tr><td>	1901	</td><td>	People & Society/Family & Relationships	</td></tr>
<tr><td>	1902	</td><td>	People & Society/Family & Relationships/Family	</td></tr>
<tr><td>	1903	</td><td>	People & Society/Family & Relationships/Marriage	</td></tr>
<tr><td>	1904	</td><td>	People & Society/Family & Relationships/Troubled Relationships	</td></tr>
<tr><td>	1905	</td><td>	People & Society/Kids & Teens	</td></tr>
<tr><td>	1906	</td><td>	People & Society/Kids & Teens/Children's Interests	</td></tr>
<tr><td>	1907	</td><td>	People & Society/Kids & Teens/Teen Interests	</td></tr>
<tr><td>	1908	</td><td>	People & Society/Religion & Belief	</td></tr>
<tr><td>	1909	</td><td>	People & Society/Seniors & Retirement	</td></tr>
<tr><td>	1910	</td><td>	People & Society/Social Issues & Advocacy	</td></tr>
<tr><td>	1911	</td><td>	People & Society/Social Issues & Advocacy/Charity & Philanthropy	</td></tr>
<tr><td>	1912	</td><td>	People & Society/Social Issues & Advocacy/Discrimination & Identity Relations	</td></tr>
<tr><td>	1913	</td><td>	People & Society/Social Issues & Advocacy/Green Living & Environmental Issues	</td></tr>
<tr><td>	1914	</td><td>	People & Society/Social Issues & Advocacy/Human Rights & Liberties	</td></tr>
<tr><td>	1915	</td><td>	People & Society/Social Issues & Advocacy/Poverty & Hunger	</td></tr>
<tr><td>	1916	</td><td>	People & Society/Social Issues & Advocacy/Work & Labor Issues	</td></tr>
<tr><td>	1917	</td><td>	People & Society/Social Sciences	</td></tr>
<tr><td>	1918	</td><td>	People & Society/Social Sciences/Economics	</td></tr>
<tr><td>	1919	</td><td>	People & Society/Social Sciences/Political Science	</td></tr>
<tr><td>	1920	</td><td>	People & Society/Social Sciences/Psychology	</td></tr>
<tr><td>	1921	</td><td>	People & Society/Subcultures & Niche Interests	</td></tr>
<tr><td>	2000	</td><td>	Pets & Animals	</td></tr>
<tr><td>	2001	</td><td>	Pets & Animals/Animal Products & Services/Pet Food & Supplies	</td></tr>
<tr><td>	2002	</td><td>	Pets & Animals/Animal Products & Services/Veterinarians	</td></tr>
<tr><td>	2003	</td><td>	Pets & Animals/Pets	</td></tr>
<tr><td>	2004	</td><td>	Pets & Animals/Pets/Birds	</td></tr>
<tr><td>	2005	</td><td>	Pets & Animals/Pets/Cats	</td></tr>
<tr><td>	2006	</td><td>	Pets & Animals/Pets/Dogs	</td></tr>
<tr><td>	2007	</td><td>	Pets & Animals/Pets/Exotic Pets	</td></tr>
<tr><td>	2008	</td><td>	Pets & Animals/Pets/Fish & Aquaria	</td></tr>
<tr><td>	2009	</td><td>	Pets & Animals/Pets/Horses	</td></tr>
<tr><td>	2010	</td><td>	Pets & Animals/Pets/Rabbits & Rodents	</td></tr>
<tr><td>	2011	</td><td>	Pets & Animals/Pets/Reptiles & Amphibians	</td></tr>
<tr><td>	2012	</td><td>	Pets & Animals/Wildlife	</td></tr>
<tr><td>	2100	</td><td>	Real Estate	</td></tr>
<tr><td>	2101	</td><td>	Real Estate/Real Estate Listings	</td></tr>
<tr><td>	2102	</td><td>	Real Estate/Real Estate Listings/Bank-Owned & Foreclosed Properties	</td></tr>
<tr><td>	2103	</td><td>	Real Estate/Real Estate Listings/Commercial Properties	</td></tr>
<tr><td>	2104	</td><td>	Real Estate/Real Estate Listings/Lots & Land	</td></tr>
<tr><td>	2105	</td><td>	Real Estate/Real Estate Listings/Residential Rentals	</td></tr>
<tr><td>	2106	</td><td>	Real Estate/Real Estate Listings/Residential Sales	</td></tr>
<tr><td>	2107	</td><td>	Real Estate/Real Estate Listings/Timeshares & Vacation Properties	</td></tr>
<tr><td>	2108	</td><td>	Real Estate/Real Estate Services	</td></tr>
<tr><td>	2200	</td><td>	Reference	</td></tr>
<tr><td>	2201	</td><td>	Reference/Directories & Listings	</td></tr>
<tr><td>	2202	</td><td>	Reference/Directories & Listings/Business & Personal Listings	</td></tr>
<tr><td>	2203	</td><td>	Reference/General Reference	</td></tr>
<tr><td>	2204	</td><td>	Reference/General Reference/Biographies & Quotations	</td></tr>
<tr><td>	2205	</td><td>	Reference/General Reference/Calculators & Reference Tools	</td></tr>
<tr><td>	2206	</td><td>	Reference/General Reference/Dictionaries & Encyclopedias	</td></tr>
<tr><td>	2207	</td><td>	Reference/General Reference/Forms Guides & Templates	</td></tr>
<tr><td>	2208	</td><td>	Reference/General Reference/Public Records	</td></tr>
<tr><td>	2209	</td><td>	Reference/General Reference/Time & Calendars	</td></tr>
<tr><td>	2210	</td><td>	Reference/Geographic Reference	</td></tr>
<tr><td>	2211	</td><td>	Reference/Geographic Reference/Maps	</td></tr>
<tr><td>	2212	</td><td>	Reference/Humanities	</td></tr>
<tr><td>	2213	</td><td>	Reference/Humanities/History	</td></tr>
<tr><td>	2214	</td><td>	Reference/Humanities/Myth & Folklore	</td></tr>
<tr><td>	2215	</td><td>	Reference/Humanities/Philosophy	</td></tr>
<tr><td>	2216	</td><td>	Reference/Language Resources	</td></tr>
<tr><td>	2217	</td><td>	Reference/Language Resources/Foreign Language Resources	</td></tr>
<tr><td>	2218	</td><td>	Reference/Libraries & Museums	</td></tr>
<tr><td>	2219	</td><td>	Reference/Libraries & Museums/Museums	</td></tr>
<tr><td>	2300	</td><td>	Science	</td></tr>
<tr><td>	2301	</td><td>	Science/Astronomy	</td></tr>
<tr><td>	2302	</td><td>	Science/Biological Sciences	</td></tr>
<tr><td>	2303	</td><td>	Science/Biological Sciences/Neuroscience	</td></tr>
<tr><td>	2304	</td><td>	Science/Chemistry	</td></tr>
<tr><td>	2305	</td><td>	Science/Computer Science	</td></tr>
<tr><td>	2306	</td><td>	Science/Earth Sciences	</td></tr>
<tr><td>	2307	</td><td>	Science/Earth Sciences/Atmospheric Science	</td></tr>
<tr><td>	2308	</td><td>	Science/Earth Sciences/Geology	</td></tr>
<tr><td>	2309	</td><td>	Science/Ecology & Environment	</td></tr>
<tr><td>	2310	</td><td>	Science/Ecology & Environment/Climate Change & Global Warming	</td></tr>
<tr><td>	2311	</td><td>	Science/Engineering & Technology	</td></tr>
<tr><td>	2312	</td><td>	Science/Engineering & Technology/Robotics	</td></tr>
<tr><td>	2313	</td><td>	Science/Mathematics	</td></tr>
<tr><td>	2314	</td><td>	Science/Mathematics/Statistics	</td></tr>
<tr><td>	2315	</td><td>	Science/Physics	</td></tr>
<tr><td>	2316	</td><td>	Science/Scientific Institutions	</td></tr>
<tr><td>	2400	</td><td>	Sensitive Subjects	</td></tr>
<tr><td>	2500	</td><td>	Shopping	</td></tr>
<tr><td>	2501	</td><td>	Shopping/Antiques & Collectibles	</td></tr>
<tr><td>	2502	</td><td>	Shopping/Apparel	</td></tr>
<tr><td>	2503	</td><td>	Shopping/Apparel/Athletic Apparel	</td></tr>
<tr><td>	2504	</td><td>	Shopping/Apparel/Casual Apparel	</td></tr>
<tr><td>	2505	</td><td>	Shopping/Apparel/Children's Clothing	</td></tr>
<tr><td>	2506	</td><td>	Shopping/Apparel/Clothing Accessories	</td></tr>
<tr><td>	2507	</td><td>	Shopping/Apparel/Costumes	</td></tr>
<tr><td>	2508	</td><td>	Shopping/Apparel/Eyewear	</td></tr>
<tr><td>	2509	</td><td>	Shopping/Apparel/Footwear	</td></tr>
<tr><td>	2510	</td><td>	Shopping/Apparel/Formal Wear	</td></tr>
<tr><td>	2511	</td><td>	Shopping/Apparel/Headwear	</td></tr>
<tr><td>	2512	</td><td>	Shopping/Apparel/Men's Clothing	</td></tr>
<tr><td>	2513	</td><td>	Shopping/Apparel/Swimwear	</td></tr>
<tr><td>	2514	</td><td>	Shopping/Apparel/Undergarments	</td></tr>
<tr><td>	2515	</td><td>	Shopping/Apparel/Women's Clothing	</td></tr>
<tr><td>	2516	</td><td>	Shopping/Auctions	</td></tr>
<tr><td>	2517	</td><td>	Shopping/Classifieds	</td></tr>
<tr><td>	2518	</td><td>	Shopping/Consumer Resources	</td></tr>
<tr><td>	2519	</td><td>	Shopping/Consumer Resources/Consumer Advocacy & Protection	</td></tr>
<tr><td>	2520	</td><td>	Shopping/Consumer Resources/Coupons & Discount Offers	</td></tr>
<tr><td>	2521	</td><td>	Shopping/Consumer Resources/Product Reviews & Price Comparisons	</td></tr>
<tr><td>	2522	</td><td>	Shopping/Entertainment Media	</td></tr>
<tr><td>	2523	</td><td>	Shopping/Entertainment Media/Entertainment Media Rentals	</td></tr>
<tr><td>	2524	</td><td>	Shopping/Gifts & Special Event Items	</td></tr>
<tr><td>	2525	</td><td>	Shopping/Gifts & Special Event Items/Cards & Greetings	</td></tr>
<tr><td>	2526	</td><td>	Shopping/Gifts & Special Event Items/Flowers	</td></tr>
<tr><td>	2527	</td><td>	Shopping/Gifts & Special Event Items/Gifts	</td></tr>
<tr><td>	2528	</td><td>	Shopping/Luxury Goods	</td></tr>
<tr><td>	2529	</td><td>	Shopping/Mass Merchants & Department Stores	</td></tr>
<tr><td>	2530	</td><td>	Shopping/Photo & Video Services	</td></tr>
<tr><td>	2531	</td><td>	Shopping/Tobacco Products	</td></tr>
<tr><td>	2532	</td><td>	Shopping/Toys	</td></tr>
<tr><td>	2533	</td><td>	Shopping/Toys/Building Toys	</td></tr>
<tr><td>	2534	</td><td>	Shopping/Toys/Die-cast & Toy Vehicles	</td></tr>
<tr><td>	2535	</td><td>	Shopping/Toys/Dolls & Accessories	</td></tr>
<tr><td>	2536	</td><td>	Shopping/Toys/Ride-On Toys & Wagons	</td></tr>
<tr><td>	2537	</td><td>	Shopping/Toys/Stuffed Toys	</td></tr>
<tr><td>	2600	</td><td>	Sports	</td></tr>
<tr><td>	2601	</td><td>	Sports/Animal Sports	</td></tr>
<tr><td>	2602	</td><td>	Sports/College Sports	</td></tr>
<tr><td>	2603	</td><td>	Sports/Combat Sports	</td></tr>
<tr><td>	2604	</td><td>	Sports/Combat Sports/Boxing	</td></tr>
<tr><td>	2605	</td><td>	Sports/Combat Sports/Martial Arts	</td></tr>
<tr><td>	2606	</td><td>	Sports/Combat Sports/Wrestling	</td></tr>
<tr><td>	2607	</td><td>	Sports/Extreme Sports	</td></tr>
<tr><td>	2608	</td><td>	Sports/Extreme Sports/Drag & Street Racing	</td></tr>
<tr><td>	2609	</td><td>	Sports/Fantasy Sports	</td></tr>
<tr><td>	2610	</td><td>	Sports/Individual Sports	</td></tr>
<tr><td>	2611	</td><td>	Sports/Individual Sports/Cycling	</td></tr>
<tr><td>	2612	</td><td>	Sports/Individual Sports/Golf	</td></tr>
<tr><td>	2613	</td><td>	Sports/Individual Sports/Gymnastics	</td></tr>
<tr><td>	2614	</td><td>	Sports/Individual Sports/Racquet Sports	</td></tr>
<tr><td>	2615	</td><td>	Sports/Individual Sports/Skate Sports	</td></tr>
<tr><td>	2616	</td><td>	Sports/Individual Sports/Track & Field	</td></tr>
<tr><td>	2617	</td><td>	Sports/International Sports Competitions	</td></tr>
<tr><td>	2618	</td><td>	Sports/International Sports Competitions/Olympics	</td></tr>
<tr><td>	2619	</td><td>	Sports/Motor Sports	</td></tr>
<tr><td>	2620	</td><td>	Sports/Sporting Goods	</td></tr>
<tr><td>	2621	</td><td>	Sports/Sporting Goods/Sports Memorabilia	</td></tr>
<tr><td>	2622	</td><td>	Sports/Sporting Goods/Winter Sports Equipment	</td></tr>
<tr><td>	2623	</td><td>	Sports/Sports Coaching & Training	</td></tr>
<tr><td>	2624	</td><td>	Sports/Team Sports	</td></tr>
<tr><td>	2625	</td><td>	Sports/Team Sports/American Football	</td></tr>
<tr><td>	2626	</td><td>	Sports/Team Sports/Australian Football	</td></tr>
<tr><td>	2627	</td><td>	Sports/Team Sports/Baseball	</td></tr>
<tr><td>	2628	</td><td>	Sports/Team Sports/Basketball	</td></tr>
<tr><td>	2629	</td><td>	Sports/Team Sports/Cheerleading	</td></tr>
<tr><td>	2630	</td><td>	Sports/Team Sports/Cricket	</td></tr>
<tr><td>	2631	</td><td>	Sports/Team Sports/Hockey	</td></tr>
<tr><td>	2632	</td><td>	Sports/Team Sports/Rugby	</td></tr>
<tr><td>	2633	</td><td>	Sports/Team Sports/Soccer	</td></tr>
<tr><td>	2634	</td><td>	Sports/Team Sports/Volleyball	</td></tr>
<tr><td>	2635	</td><td>	Sports/Water Sports	</td></tr>
<tr><td>	2636	</td><td>	Sports/Water Sports/Surfing	</td></tr>
<tr><td>	2637	</td><td>	Sports/Water Sports/Swimming	</td></tr>
<tr><td>	2638	</td><td>	Sports/Winter Sports	</td></tr>
<tr><td>	2639	</td><td>	Sports/Winter Sports/Ice Skating	</td></tr>
<tr><td>	2640	</td><td>	Sports/Winter Sports/Skiing & Snowboarding	</td></tr>
<tr><td>	2700	</td><td>	Travel	</td></tr>
<tr><td>	2701	</td><td>	Travel/Air Travel	</td></tr>
<tr><td>	2702	</td><td>	Travel/Air Travel/Airport Parking & Transportation	</td></tr>
<tr><td>	2703	</td><td>	Travel/Bus & Rail	</td></tr>
<tr><td>	2704	</td><td>	Travel/Car Rental & Taxi Services	</td></tr>
<tr><td>	2705	</td><td>	Travel/Cruises & Charters	</td></tr>
<tr><td>	2706	</td><td>	Travel/Hotels & Accommodations	</td></tr>
<tr><td>	2707	</td><td>	Travel/Hotels & Accommodations/Vacation Rentals & Short-Term Stays	</td></tr>
<tr><td>	2708	</td><td>	Travel/Specialty Travel	</td></tr>
<tr><td>	2709	</td><td>	Travel/Tourist Destinations	</td></tr>
<tr><td>	2710	</td><td>	Travel/Tourist Destinations/Beaches & Islands	</td></tr>
<tr><td>	2711	</td><td>	Travel/Tourist Destinations/Mountain & Ski Resorts	</td></tr>
<tr><td>	2712	</td><td>	Travel/Tourist Destinations/Regional Parks & Gardens	</td></tr>
<tr><td>	2713	</td><td>	Travel/Tourist Destinations/Theme Parks	</td></tr>
<tr><td>	2714	</td><td>	Travel/Tourist Destinations/Zoos-Aquariums-Preserves	</td></tr>
<tr><td>	4000	</td><td>	Weapon	</td></tr>
<tr><td>	4100	</td><td>	Inappropriate Subjects	</td></tr>
<tr><td>	4101	</td><td>	Inappropriate Subjects/Nudity	</td></tr>
<tr><td>	4102	</td><td>	Inappropriate Subjects/Questionable	</td></tr>
<tr><td>	4103	</td><td>	Inappropriate Subjects/Hate and Racism	</td></tr>
<tr><td>	4104	</td><td>	Inappropriate Subjects/Violance	</td></tr>
<tr><td>	4105	</td><td>	Inappropriate Subjects/Cheating	</td></tr>
<tr><td>	4106	</td><td>	Inappropriate Subjects/Gross	</td></tr>
<tr><td>	4107	</td><td>	Inappropriate Subjects/Malware	</td></tr>
<tr><td>	4108	</td><td>	Inappropriate Subjects/Phishing and Other Frauds	</td></tr>
<tr><td>	4109	</td><td>	Inappropriate Subjects/Proxy Avoidance and Anonymizers	</td></tr>
<tr><td>	4110	</td><td>	Inappropriate Subjects/Spyware and Adware	</td></tr>
<tr><td>	4111	</td><td>	Inappropriate Subjects/Illegal	</td></tr>
<tr><td>	4112	</td><td>	Inappropriate Subjects/Bot Nets	</td></tr>
<tr><td>	4113	</td><td>	Inappropriate Subjects/Spam	</td></tr>
<tr><td>	100000	</td><td>	Unreachable	</td></tr>
<tr><td>	100001	</td><td>	Parked domains	</td></tr>

                      </table>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.description}>
                <p>

                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(pricingPageStyle)(CategoriesPage);
